import React from "react";
import "./App.css";
import Lottie from "react-lottie";
import animationData from "./assets/animation/Animation - 1706702794228.json";
import logo from "./assets/image/StartPoint-logo.png";

function App() {
  const redirectToPage1 = () => {
    window.location.href = "https://operator.bazar24.az/";
  };

  const redirectToPage2 = () => {
    window.location.href = "https://owner.bazar24.az/";
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <section className="login_page">
        <div className="login_wrapper">
          <div className="login_image">
            <div className="logo">
              <img src={logo} />
            </div>
            <div>
              <Lottie options={defaultOptions} height={400} width={"100%"} />
            </div>
          </div>
          <div className="login_url">
            <div className="login_url_body">
              <span>Operator səhifəsinə keçid:</span>
              <button onClick={redirectToPage1}>Operator</button>
            </div>
            <div className="login_url_body">
              <span>İdarəetmə səhifəsinə keçid:</span>
              <button onClick={redirectToPage2}>Owner</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default App;
